import {
  ConversationResponseModel,
  useCallHistory,
} from '@capturi/api-conversations'
import { useAudioContext } from '@capturi/audio'
import { useFeatureFlags } from '@capturi/feature-flags'
import { useOrganization } from '@capturi/stores'
import { ContentPlaceholder, Emoji } from '@capturi/ui-components'
import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react'
import { Trans, select } from '@lingui/macro'
import { useConversationDetailsDrawer } from 'components/ConversationDetailsDrawer'
import React, { useState } from 'react' // Add useState import
import { useEffectOnce } from 'react-use'

import { ConversationPageView } from 'pages/Conversations/routes'
import { ConversationTableRow } from '../../../pages/Conversations/ConversationTableRow'
import { Hit } from '../Audio/types'
import { Event, log } from '../events'

type HistoryViewProps = {
  conversation: ConversationResponseModel
  setHits: (hits: Hit[]) => void
  navigateToConversation: (uid: string, tabName?: ConversationPageView) => void
}

const HistoryView: React.FC<HistoryViewProps> = ({ conversation, setHits }) => {
  const { customer, uid: conversationUid } = conversation
  const { showStatus, aiFeatures } = useFeatureFlags()
  const { organizationType } = useOrganization()

  const { stop } = useAudioContext()

  const { data } = useCallHistory(customer)

  // Add state for last selected conversation UID
  const [lastSelectedUid, setLastSelectedUid] = useState<string | null>(null)

  useEffectOnce(() => {
    setHits?.([])
  })

  const conversations = data?.conversations || []

  const openConversationDetailsDrawer = useConversationDetailsDrawer()

  const handleConversationSelected = React.useCallback(
    (uid: string) => {
      if (uid && uid !== conversationUid) {
        log(Event.GoToPreviousConversation)
        stop()
        setLastSelectedUid(uid) // Update last selected UID
        openConversationDetailsDrawer({
          uid: uid,
          showOverlay: true,
        })
      }
    },
    [conversationUid, openConversationDetailsDrawer, stop],
  )

  if (conversations.length === 0) {
    return (
      <ContentPlaceholder.Container mt={8} size="md">
        <ContentPlaceholder.Heading>
          <Trans>No previous conversations</Trans>
          <Emoji symbol="📞" label="telephone" fontSize="4xl" pl={2} />
        </ContentPlaceholder.Heading>
        <ContentPlaceholder.Body>
          {select(organizationType, {
            public: 'There are no other conversations with this citizen',
            other: 'There are no other conversations with this customer',
          })}
        </ContentPlaceholder.Body>
      </ContentPlaceholder.Container>
    )
  }

  return (
    <Table>
      <Thead>
        <Tr>
          <Th w="15%" pl="4">
            <Trans>Date</Trans>
          </Th>
          <Th w="20%" pl="4">
            <Trans>Queue / campaign</Trans>
          </Th>
          {aiFeatures && (
            <Th w="30%" pl="4">
              <Trans>Key topic</Trans>
            </Th>
          )}

          {showStatus && (
            <Th w="15%" pl="4">
              <Trans>Status</Trans>
            </Th>
          )}
          <Th w="10%" pl="4">
            <Trans>Duration</Trans>
          </Th>

          <Th w="30%" pl="4">
            <Trans>Employee</Trans>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {conversations.map((c) => (
          <ConversationTableRow
            key={c.uid}
            onSelectConversation={handleConversationSelected}
            {...c}
            backgroundColor={
              conversationUid === c.uid
                ? 'primary.50'
                : lastSelectedUid === c.uid
                  ? 'gray.200'
                  : undefined // Set background color for last selected
            }
            cursor={conversationUid === c.uid ? 'default' : 'pointer'}
            showStatus={showStatus}
          />
        ))}
      </Tbody>
    </Table>
  )
}

export default HistoryView
